import ReactDOMServer from "react-dom/server";
import { Feature } from "ol";
import { Point } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Select } from "ol/interaction";
import { click } from "ol/events/condition";
import { Icon, Style } from "ol/style";
import { ProjectResponse } from "../map-setup-context/InitialMapValues";
import { clickedMarker } from "./logos";
import useCreateShapedLogo from "./useCreateShapedLogo";
import getProjectFillColour, {
  getProjectIcon,
  getDifferentiatedProjectCoordinatePoint,
} from "./utils";

const createIconStyle = (
  src: string,
  height: number,
  anchor: [number, number],
) => {
  return new Style({
    image: new Icon({
      anchor,
      src,
      height,
      displacement: [0, 0],
      offset: [0, 0],
    }),
  });
};

const getMarkerLayer = (
  projects: ProjectResponse[],
  setPopupInfo: (popupInfo: ProjectResponse) => void,
  setShowPopup: (showPopup: boolean) => void,
) => {
  const vectorSource = new VectorSource();

  const features = projects.map((project) => {
    const projectType = project.interventions[0]?.biome ?? "Forest";
    const targetCenter = getDifferentiatedProjectCoordinatePoint(project);

    const iconFeature = new Feature({
      geometry: new Point(targetCenter),
      id: `marker-${project.id}`,
      project,
    });
    const logo = getProjectIcon(projectType);
    const fillColour = getProjectFillColour(projectType) ?? [64, 228, 171];
    const markerLogo = useCreateShapedLogo({ size: 250, fillColour, logo });
    const iconStyle = createIconStyle(markerLogo, 70, [0.4, 0.7]);

    iconFeature.setStyle(iconStyle);

    return iconFeature;
  });

  vectorSource.addFeatures(features);

  const vectorLayer = new VectorLayer({
    source: vectorSource,
    className: "marker-layer",
  });

  vectorLayer.set("data-testid", "marker-layer");

  const handleFeatureSelect = (selectedFeature: Feature | undefined) => {
    if (selectedFeature) {
      const project = selectedFeature.get("project") as ProjectResponse;
      const logoString = ReactDOMServer.renderToString(clickedMarker);
      const svgBlob = new Blob([logoString], {
        type: "image/svg+xml;charset=utf-8",
      });
      const url = URL.createObjectURL(svgBlob);
      const selectedIconStyle = createIconStyle(url, 80, [0.1, 0.3]);

      selectedFeature.setStyle(selectedIconStyle);
      setPopupInfo(project);
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  };

  const selectClick = new Select({
    condition: click,
    layers: [vectorLayer],
  });

  selectClick.on("select", (event) => {
    const selectedFeature = event.selected[0];
    handleFeatureSelect(selectedFeature);
  });

  return {
    vectorLayer,
    selectClick,
  };
};

export default getMarkerLayer;
