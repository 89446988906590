import React from "react";
import styled from "styled-components";
import { ScaleLine } from "ol/control";
import { FlexColumn } from "../../../styles";
import { ProjectResponse } from "../map-setup-context/InitialMapValues";
import { useMapContext } from "../map-setup-context/MapContext";
import { getDifferentiatedProjectCoordinatePoint } from "../map-layers/utils";
import { addOrRemoveControlButtons } from "../map-setup-context/utils";

const DropdownItem = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  background-color: white;
  word-break: break-word;
  border: grey solid 2px;
  box-shadow: -5px 12px 16px rgba(0, 0, 0, 0.15);
  padding: 8px 16px;
  width: 200px;
  z-index: 10;
  &:hover {
    background-color: #40e4ab;
    color: #ffffff;
    font-weight: 600;
    img {
      filter: invert(1);
    }
  }
`;

function FlyToButton({ project }: { project: ProjectResponse }) {
  const {
    mapRef,
    setShowDropdownMenu,
    setShowPopup,
    setPopupInfo,
    setProjectHighlight,
  } = useMapContext();

  const handleButtonClick = () => {
    if (mapRef.current) {
      const view = mapRef.current.getView();
      const targetCenter = getDifferentiatedProjectCoordinatePoint(project);
      view.animate({
        center: targetCenter,
        zoom: 17,
        duration: 2000,
      });
      addOrRemoveControlButtons(mapRef.current, ScaleLine, "add", {
        units: "us",
        className: "custom-scale-line",
      });
      setShowPopup(false);
      setPopupInfo(project);
      setShowDropdownMenu(false);
      if (project.unitData) {
        setProjectHighlight(project);
      }

      setTimeout(() => {
        setShowPopup(true);
      }, 2000);
    }
  };

  return (
    <DropdownItem onClick={handleButtonClick}>{project.name}</DropdownItem>
  );
}

export default FlyToButton;
