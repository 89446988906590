import React, { useEffect, useRef } from "react";
import "ol/ol.css";
import styled from "styled-components";
import { useMapContext } from "./map-setup-context/MapContext";
import { FlexRow } from "../../styles";

const MapDisplay = styled(FlexRow)`
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  height: 700px;
  z-index: 8;
  @media (max-width: 800px) {
    height: 750px;
  }
`;

function MapComponent() {
  const { mapRef, dataId } = useMapContext();
  const mapContainerRef = useRef(null);

  useEffect(() => {
    const map = mapRef.current;
    if (map && mapContainerRef.current) {
      map.setTarget(mapContainerRef.current);
      map.updateSize();
    }
    return () => {
      if (map) {
        map.setTarget(undefined);
      }
    };
  }, [mapRef, mapContainerRef]);

  return (
    <MapDisplay
      ref={mapContainerRef}
      id="map-container"
      data-testid={`map-container-${dataId}`}
      className="map-container"
      style={{ height: 700, width: "100%" }}
    />
  );
}

export default MapComponent;
