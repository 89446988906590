import { ReactElement } from "react";
import { fromLonLat } from "ol/proj";
import { Forest, Grassland, Peatland, Farmland, OceanAndCoast } from "./logos";
import { ProjectResponse } from "../map-setup-context/InitialMapValues";

const availableFillColours = {
  EARTHLY: "#40e4ab" as const,
  SAND: "#D9CA80" as const,
  SUN: "#E35E40" as const,
  CARBON: "#797979" as const,
  SEA: "#3062C0" as const,
  SKY: "#76BEC6" as const,
  PLAIN: "#ffffff" as const,
} as const;

type FillColours =
  (typeof availableFillColours)[keyof typeof availableFillColours];

type ProjectStyles = {
  icon: ReactElement;
  colour: FillColours;
};

const projectDetailsMapper: Record<string, ProjectStyles> = {
  Forest: { icon: Forest, colour: availableFillColours.EARTHLY },
  Grassland: { icon: Grassland, colour: availableFillColours.SAND },
  Peatland: { icon: Peatland, colour: availableFillColours.SUN },
  Farmland: { icon: Farmland, colour: availableFillColours.PLAIN },
  "Ocean And Coast": { icon: OceanAndCoast, colour: availableFillColours.SKY },
};

export function getProjectIcon(type: string): ReactElement | null {
  return projectDetailsMapper[type]?.icon || null;
}

export function getProjectFillColour(type: string): string {
  return projectDetailsMapper[type]?.colour;
}

export function getCheckIfValidDegreeCoordinate(
  coord: number,
  type: "lat" | "lon",
) {
  if (typeof coord !== "number" || Number.isNaN(coord)) return false;
  if (type === "lat" && (coord < -90 || coord > 90)) return false;
  if (type === "lon" && (coord < -180 || coord > 180)) return false;
  return true;
}

export function getDifferentiatedProjectCoordinatePoint(
  project: ProjectResponse,
) {
  const flyToCoordinates = [-0.05813303, 51.519018];
  const nonBiodiversityCoords = project.centerCoordinates;
  const biodiversityCoords = project?.unitData?.geometry.features[0]?.geometry
    .coordinates[0][0][0] as number[] | undefined;

  let coordinatesToFlyTo: number[] = flyToCoordinates;

  if (biodiversityCoords) {
    coordinatesToFlyTo = biodiversityCoords;
  } else if (
    nonBiodiversityCoords &&
    getCheckIfValidDegreeCoordinate(nonBiodiversityCoords.lat, "lat") &&
    getCheckIfValidDegreeCoordinate(nonBiodiversityCoords.lon, "lon")
  ) {
    coordinatesToFlyTo = [nonBiodiversityCoords.lon, nonBiodiversityCoords.lat];
  } else {
    coordinatesToFlyTo = flyToCoordinates;
  }

  const coordinatePoint = fromLonLat(coordinatesToFlyTo);
  return coordinatePoint;
}

export default getProjectFillColour;
