import { useState } from "react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { MapContexts, ProjectResponse } from "./InitialMapValues";

export default function useMapStates(defaultContext: MapContexts) {
  const [showMap, setShowMap] = useState(false);
  const { clientId } = useParams<{ clientId: string }>();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupInfo, setPopupInfo] = useState<ProjectResponse>(
    defaultContext.popupInfo,
  );
  const [projectHighlight, setProjectHighlight] = useState<
    ProjectResponse | undefined
  >(defaultContext.projectHighlight);
  const [dataId, setDataId] = useState("");

  return {
    showMap,
    setShowMap,
    showDropdownMenu,
    setShowDropdownMenu,
    projectHighlight,
    setProjectHighlight,
    showPopup,
    setShowPopup,
    popupInfo,
    setPopupInfo,
    dataId,
    setDataId,
    clientId,
    isMobile,
  };
}
