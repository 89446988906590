import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FlexColumn } from "../../styles";
import MapComponent from "./MapComponent";
import MapButtonRow from "./map-button-row";
import PopupComponent from "./PopupComponent";
import { useMapContext } from "./map-setup-context/MapContext";
import {
  ProjectResponse,
  defaultContext,
} from "./map-setup-context/InitialMapValues";

export const StyledWrapper = styled(FlexColumn)`
  height: auto;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 16px;
`;

export const Container = styled(FlexColumn)`
  border-radius: 16px;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: 64px;
  width: 100%;
  height: 700px;
  background-color: white;
  @media (max-width: 800px) {
    height: 750px;
    margin-bottom: 0;
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

function Wrapper({ children }: { children: React.ReactNode }) {
  return (
    <StyledWrapper>
      <Container>{children}</Container>
    </StyledWrapper>
  );
}

function ImpactMap({ projects }: { projects: ProjectResponse[] }) {
  const { popupInfo, showPopup, setProjects: setMapProjects } = useMapContext();
  const [popupCardInfo, setPopupCardInfo] = useState(defaultContext.popupInfo);

  useEffect(() => {
    if (projects) {
      setMapProjects(projects);
      setPopupCardInfo(popupInfo);
    }
  }, [projects, setMapProjects, popupInfo]);

  if (projects.length === 0) return null;
  return (
    <Wrapper>
      <MapButtonRow />
      <MapComponent />
      {showPopup && <PopupComponent project={popupCardInfo} />}
    </Wrapper>
  );
}

export default ImpactMap;
